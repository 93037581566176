<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Admin : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- email -->
          <b-form-group label="Email" label-for="Email">
            <validation-provider #default="{ errors }" name="Email">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Cari Email..."
                  v-model="filter.oadm_email"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- nama_lengkap -->
          <b-form-group label="Nama Lengkap" label-for="nama_lengkap">
            <validation-provider #default="{ errors }" name="nama_lengkap">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="nama_lengkap"
                  placeholder="Cari nama lengkap..."
                  v-model="filter.oadm_name"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status pendaftaran jadwal krip -->
          <b-form-group label="Status Admin" label-for="status_admin">
            <validation-provider #default="{ errors }" name="status_admin">
              <v-select
                id="status_admin"
                v-model="filter.oadm_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusList"
                placeholder="Pilih status admin..."
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status pendaftaran jadwal krip -->
          <b-form-group label="Tanggal" label-for="tanggal">
            <validation-provider #default="{ errors }" name="tanggal">
              <b-form-input
                id="tanggal"
                placeholder="Cari tanggal..."
                v-model="filter.created_at"
                type="date"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage()"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col lg="20" md="12" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-2 mt-md-0"
            @click="addAdmin()"
          >
            <feather-icon icon="PlusIcon" class="mr-26" />
            <span>Tambah Admin</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <!-- data table -->

    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="showDetailAdmin(data.item)">
            <feather-icon icon="SettingsIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showModalEditAdmin(data.item)">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteAdmin(data.item)">
            <feather-icon icon="XCircleIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>

    <!-- list data calon angggota-->
    <b-modal id="modal-detail-admin" size="xl" hide-footer>
      <template #modal-title>Detail Data </template>
      <!-- Invoice Description: Total -->
      <table style="width: 100%">
        <b-table-simple
          hover
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th colspan="7" class="text-right">
                #{{ detailAdmin.id }} [{{ detailAdmin.oadm_status }}] [{{
                  detailAdmin.oadm_role
                }}]
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-th rowspan="20">
                Data Diri
              </b-th>
              <b-th class="text-right">
                Email :
              </b-th>
              <b-td>{{ detailAdmin.oadm_email }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Foto :
              </b-th>
              <b-td v-if="detailAdmin.oadm_photo != null">
                <b-td>
                  <img
                    :src="detailAdmin.oadm_photo"
                    alt="foto"
                    width="200"
                    height="300"
                  />
                </b-td>
              </b-td>
              <b-td v-else>{{ "foto tidak ada" }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Google Secret :
              </b-th>
              <b-td v-if="detailAdmin.google_secret">
                {{ detailAdmin.google_secret }}</b-td
              >
              <b-td v-else> {{ "Tidak Ada" }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Passchange Token :
              </b-th>
              <b-td v-if="detailAdmin.oadm_passchange_token">
                {{ detailAdmin.oadm_passchange_token }}</b-td
              >
              <b-td v-else> {{ "Tidak Ada" }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Google Secret Token :
              </b-th>
              <b-td v-if="detailAdmin.reset_google_secret_token">
                {{ detailAdmin.reset_google_secret_token }}</b-td
              >
              <b-td v-else> {{ "Tidak Ada" }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Factor Is Active :
              </b-th>
              <b-td v-if="detailAdmin.two_factor_is_active">
                {{ detailAdmin.two_factor_is_active }}</b-td
              >
              <b-td v-else> {{ "Tidak Ada" }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Dibuat :
              </b-th>
              <b-td> {{ detailAdmin.created_at }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Diupdate :
              </b-th>
              <b-td> {{ detailAdmin.updated_at }}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td colspan="7" variant="secondary" class="text-right"> </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </table>
    </b-modal>

    <!-- list invoices and verify invoices from calon anggota -->
    <b-modal id="modal-list-invoices" size="xl" hide-footer>
      <template #modal-title>Invoices </template>
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="invoices"
        :fields="fieldsInvoices"
        responsive
        class="mb-0"
        @row-selected="onRowSelected"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="showVerifyInvoices(data.item)">
              <feather-icon icon="SettingsIcon" size="16" />
              <span class="align-middle ml-50">Verify</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="currentPageInvoices"
            hide-goto-end-buttons
            :total-rows="rowsInvoices"
            :per-page="perPageInvoices"
          />
        </div>
      </b-card-body>
    </b-modal>

    <!-- list reksip -->
    <b-modal id="modal-list-reksip" size="xl" hide-footer>
      <template #modal-title> Rekomnedasi Izin Praktek </template>
      <!-- Invoice Description: Total invoice data -->
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="reskipes"
        :fields="list_reskipes"
        responsive
        class="mb-2"
        @row-selected="onRowInvoice"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="currentPageReskipes"
            hide-goto-end-buttons
            :total-rows="rowsReskipes"
            :per-page="perPageReskipes"
          />
        </div>
      </b-card-body>
    </b-modal>

    <!-- list pepanjangan kta -->
    <b-modal id="modal-list-perpanjangan-kta" size="xl" hide-footer>
      <template #modal-title> Perpanjang KTA </template>
      <!-- Invoice Description: Total invoice data -->
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="perpanjanganktas"
        :fields="list_perpanjangan_ktas"
        responsive
        class="mb-2"
        @row-selected="onRowInvoice"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="currenPagePerpanjanganKtas"
            hide-goto-end-buttons
            :total-rows="rowsPerpanjanganKtas"
            :per-page="PerPagePerpanjanganKtas"
          />
        </div>
      </b-card-body>
    </b-modal>

    <!-- list mutasi keluar -->
    <b-modal id="modal-list-mutasi-keluar" size="xl" hide-footer>
      <template #modal-title> Mutasi Keluar </template>
      <!-- Invoice Description: Total invoice data -->
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="mutasiKeluaras"
        :fields="list_mutasi_keluaras"
        responsive
        class="mb-2"
        @row-selected="onRowInvoice"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="currentPageMutasiKeluar"
            hide-goto-end-buttons
            :total-rows="rowsMutasiKeluaras"
            :per-page="perPageMutasiKeluar"
          />
        </div>
      </b-card-body>
    </b-modal>

    <!-- modal add admin -->
    <b-modal
      id="modal-add-admin"
      title="Tambah Admin"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-group label="Email" label-for="Email" disable>
          <b-form-input
            id="email"
            v-model="form.oadm_email"
            placeholder="Email"
          />
        </b-form-group>
        <b-form-group label="Name" label-for="Name" disable>
          <b-form-input id="name" v-model="form.oadm_name" placeholder="Name" />
        </b-form-group>
        <b-form-group label="Pasfoto" label-for="file_passphoto">
          <b-form-file
            id="file-upload-passphoto"
            v-model="upload.filePassfoto"
            accept="image/*"
            @change="handlerfilePassfoto($event)"
          />
        </b-form-group>
        <b-form-group label="Password" label-for="password">
          <validation-provider #default="{ errors }" name="Password">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="password"
                v-model="form.oadm_password"
                :type="passwordFieldType"
                class="form-control-merge"
                :state="errors.length > 0 ? false : null"
                name="login-password"
                placeholder="Password"
                autocomplete="on"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="status" label-for="status">
          <v-select
            v-model="form.oadm_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusList"
          />
        </b-form-group>
        <b-form-group label="roles" label-for="roles">
          <v-select
            v-model="form.oadm_role"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="rolesList"
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="storeDataAdmin"
        >
          Simpan
        </b-button>
      </b-form>
    </b-modal>

    <!-- modal edit anggota -->
    <b-modal
      id="modal-edit-admin"
      title="Edit Admin"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-group label="Id" label-for="id_orang " hidden>
          <b-form-input
            id="id_orang"
            v-model="detailEditAdmin.id"
            placeholder="Id Orang"
            hidden
          />
        </b-form-group>
        <b-form-group label="Email" label-for="Email" disable>
          <b-form-input
            id="email"
            v-model="detailEditAdmin.oadm_email"
            placeholder="Email"
          />
        </b-form-group>
        <b-form-group label="Name" label-for="Name" disable>
          <b-form-input
            id="name"
            v-model="detailEditAdmin.oadm_name"
            placeholder="Name"
          />
        </b-form-group>
        <b-form-group label="Pasfoto" label-for="file_passphoto">
          <b-form-file
            id="file-upload-passphoto"
            v-model="upload.filePassfoto"
            accept="image/*"
            @change="handlerfilePassfoto($event)"
          />
          <th>PassFoto:</th>
          <td v-if="detailEditAdmin.oadm_photo != null">
            <a :href="detailEditAdmin.oadm_photo" alt="foto" target="_blank"
              >Tampilkan File</a
            >
          </td>
          <td v-else>{{ "file tidak ada" }}</td>
        </b-form-group>
        <b-form-group label="Password" label-for="password">
          <validation-provider #default="{ errors }" name="Password">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="password"
                v-model="detailEditAdmin.oadm_password"
                :type="passwordFieldType"
                class="form-control-merge"
                :state="errors.length > 0 ? false : null"
                name="login-password"
                placeholder="Password"
                autocomplete="on"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="status" label-for="status">
          <v-select
            v-model="detailEditAdmin.oadm_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusList"
          />
        </b-form-group>
        <b-form-group label="roles" label-for="roles">
          <v-select
            v-model="detailEditAdmin.oadm_role"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="rolesList"
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="editDataAdmin"
        >
          Simpan
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BForm,
  BButton,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormDatepicker,
  BTfoot,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BInputGroup,
  BInputGroupAppend,
  BDropdownItem,
  BLink,
  BImg,
  BFormInput,
  BRow,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BModal,
  BFormFile,
} from "bootstrap-vue";
import API from "@/api";
import { required, email } from "@validations";
import vSelect from "vue-select";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    BButton,
    BAvatar,
    BFormFile,
    BCardBody,
    BTableSimple,
    BThead,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BImg,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BBadge,
    vSelect,
    BFormDatepicker,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      required,
      email,
      //list anggota
      fields: [
        { key: "id", label: "id" },
        [{ key: "oadm_name", label: "Nama" }],
        [{ key: "oadm_email", label: "Email" }],
        [{ key: "oadm_status", label: "Status" }],
        [{ key: "created_at", label: "Di Buat" }],
        [{ key: "updated_at", label: "Di Update" }],
        "action",
      ],
      items: [],

      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      detailAdmin: {},

      form: {},
      //filter upload foto
      upload: {
        filePassfoto: "",
      },
      detailEditAdmin: {},

      //filter(search) data anggota fields
      filter: {
        oadm_email: "",
        oadm_name: "",
        oadm_status: "",
        created_at: "",
      },

      solidColor: [{ bg: "danger", title: "Danger card title" }],
      statusList: ["active", "inactive"],
      rolesList: ["admin_umum", "pelayanan", "pengurus"],
    };
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        // this.getPendidikan()
        this.getAdmin();
      } else {
        this.currentPage = 1;
      }
    },

    showDetailAdmin(item) {
      this.getDetailAdmin(item);
      this.$bvModal.show("modal-detail-admin");
    },

    showModalEditAdmin(item) {
      this.detailEditAdmin = item;
      this.$bvModal.show("modal-edit-admin");
    },

    addAdmin() {
      this.$bvModal.show("modal-add-admin");
    },

    // handler upload foto

    handlerfilePassfoto(e) {
      const { files } = e.target;
      if (files.length) {
        this.createImagefilePassfoto(files[0], (result) => {
          this.upload.filePassfoto = result;
        });
      }
    },

    createImagefilePassfoto(file, cb) {
      const reader = new FileReader();

      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    // end handler upload foto

    async getAdmin() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const param = {
          limit: this.perPage,
          page: this.currentPage,
          ...this.filter,
        };
        const { data } = await API.admin.list(param);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data anggota");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async storeDataAdmin() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        let body = {
          oadm_name: this.form.oadm_name,
          oadm_email: this.form.oadm_email,
          oadm_password: this.form.oadm_password,
          oadm_status: this.form.oadm_status,
          oadm_role: this.form.oadm_role,
          oadm_photo: this.upload.filePassfoto
            ? this.upload.filePassfoto
            : this.form.oadm_photo,
        };
        await API.admin.create(body);
        this.handleMsgSuccess("Berhasil Tambah Admin");
        this.clearSelected();
        this.refreshPage();
      } catch (error) {
        this.handleMsgErrors(error, "gagal tambah admin");
      } finally {
        this.$bvModal.hide("modal-add-admin");
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getDetailAdmin(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const { data } = await API.admin.detail(ids.id);
        this.detailAdmin = data.data;

        this.handleMsgSuccess("Berhasil Menampilkan Detail Anggota");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async editDataAdmin() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data Admin Ini Akan Di Edit, Pastikan data yang di edit sudah sesuai dengan data yang sesungguhnya",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Edit!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.admin.update(this.detailEditAdmin.id, {
                oadm_name: this.detailEditAdmin.oadm_name,
                oadm_email: this.detailEditAdmin.oadm_email,
                oadm_password: this.detailEditAdmin.oadm_password,
                oadm_status: this.detailEditAdmin.oadm_status,
                oadm_role: this.detailEditAdmin.oadm_role,
                oadm_photo: this.upload.filePassfoto
                  ? this.upload.filePassfoto
                  : this.detailEditAdmin.oadm_photo,
              });
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Edit data admin",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
            this.$bvModal.hide("modal-edit-admin");
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async deleteAdmin(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text: "Data admin yang sudah dihapus, tidak bisa dikembalikan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.admin.delete(ids.id);
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Menghapus Data Admin",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getAdmin();
  },
  watch: {
    currentPage() {
      this.getAdmin();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
